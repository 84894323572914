<template>
  <a-modal v-model="modalVisible" :title="modalTitle" :width="660" :centered="true">
    <template slot="footer">
      <a-button v-if="showType=='add'||showType=='edit'" @click="modalVisible=false">取消</a-button>
      <a-button type="primary" @click="confirm">确认</a-button>
    </template>
    <a-form-model ref="modalForm" :model="formDatas" :rules="formRules" :label-col="{span:7}" :wrapper-col="{span:14}">
      <a-form-model-item label="代码条目名称" prop="eventname">
        <a-input v-model.trim="formDatas.eventname" placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="代码条目编码" prop="eventcode">
        <a-input v-model.trim="formDatas.eventcode" placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="代码条目类型" prop="eventtype">
        <a-select v-model="formDatas.eventtype" style="width: 180px;">
          <a-select-option v-for="(item, index) in eventOptions" :key="index" :value="item.key">{{item.value}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="关联设备类别" prop="liftpropertyids">
        <a-select v-model="formDatas.liftpropertyids" mode="multiple">
          <a-select-option v-for="(item, index) in liftpropertyidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="代码条目描述" prop="eventdesc">
        <a-input v-model.trim="formDatas.eventdesc" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import maintenanceTypes from '@/json/maintenanceTypes'
import { getLiftListByCondition } from 'A/jcgn.js'
import { addEvent, modifyEvent } from 'A/wlw.js'
import {eventOptions,eventTips} from '@/json/wlwhistory'
export default {
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
    liftpropertyidOptions:{
      default:[]
    }
  },
  data() {
    return {
      eventOptions,
      maintenanceTypes,
      // liftpropertyidOptions: [],
      modalVisible: false,
      formDatas: {
        eventid:"",
        eventname: '',
        eventcode: '',
        eventtype: '',
        liftpropertyids: [],
        eventdesc: '',
      },
      formRules: {
        eventname: [{required: true, message: '请输入代码条目名称'}],
        eventcode: [{required: true, message: '请输入代码条目编码'}],
        eventtype: [{required: true, message: '请输入代码条目类型'}],
        liftpropertyids: [{required: true, message: '请选择关联的电梯设备类型'}],
      }
    }
  },
  computed: {
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    }
  },
  created() {
    this.modalVisible = this.visible;
    // this.initLiftpropertyidOptions();
  },
  methods: {
    initLiftpropertyidOptions() {
      let params = {
        liftpropertytype: '1',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.liftpropertyidOptions = res.item;
        }
      })
    },
    initDetail() {
      if(this.detailData && (this.showType == 'edit')) {
        this.$nextTick(() => {
          for(let key in this.formDatas) {
            if(key === 'liftpropertyids'){
              let arr = this.detailData[key]
              this.formDatas[key] = arr?arr.split(",").map(v=>v*1):[]
            }else{
              this.formDatas[key] = this.detailData[key];
            }
          }
          // this.formDatas = {
          //   ...this.formDatas,
          //   ...this.detailData
          // }
        })
      }
    },
    resetDatas() {
      this.$refs.modalForm.resetFields();
    },
    confirm() {
      // success fail
      if(this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if(valid) {
            this.showLoading();
            let params = {
              ...this.formDatas,
              liftpropertyids:this.formDatas.liftpropertyids.join(",")
            };
            if(this.showType == 'add') {
              addEvent(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }else {
              modifyEvent(params).then(res => {
                this.hideLoading();
                if(res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                }else {
                  this.$message.error(res.errormsg||'操作失败');
                }
              })
            }
          }else {
            return false;
          }
        })
      }else {
        this.modalVisible = false;
      }
    }
  }
}
</script>